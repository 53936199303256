import React, { useState, useEffect } from "react";
import { notify } from "utils/Notify";
import { userService } from "services/UserService";
import SiteConfigProvider from "../utils/SiteConfigProvider";
import CartModal from "components/layouts/cartmodal";
import { navigate } from "gatsby";

export const siteContext = React.createContext();

const SiteProvider = (props) => {
    const [user, setUser] = useState({});
    const [subscription, setSubscription] = useState(null);
    const [properties, setProperties] = useState([]);
    const [orderData, setOrders] = useState({});
    const [loading, setLoading] = useState(false);
    const [isHydrated, setIsHydrated] = useState(false);
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [cart, setCart] = useState([]);
    const [addToCartModalVisible, setAddToCartModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState();

    useEffect(() => {
        setIsHydrated(true);
    }, []);

    //#region User Profile
    const loadUser = () => {
        setLoading(true);

        userService
            .getSelf()
            .then((data) => setUser(data))
            .catch((err) => notify.error("Can't load the user"))
            .finally(() => setLoading(false));
    };

    const updateEmail = (values) => {
        setLoading(true);
        values.id = user.id;

        userService
            .updateEmail(values)
            .then((data) => {
                notify.success("Your email address has been updated");

                if (data.id !== undefined) setUser(data);
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const updatePhone = (values) => {
        setLoading(true);
        values.id = user.id;

        userService
            .updatePhone(values)
            .then((data) => {
                notify.success("Your phone number has been updated");

                if (data.id !== undefined) setUser(data);
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const updateUserProfile = (values) => {
        setLoading(true);

        values.id = user.id;

        userService
            .updateName(values)
            .then((data) => {
                notify.success("Your profile has been updated");

                if (data.id !== undefined) setUser(data);
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const updateUserPassword = (values) => {
        setLoading(true);

        values.id = user.id;

        userService
            .updatePassword(values)
            .then((data) => notify.success("You have successfully updated the password"))
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const loadProperties = () => {
        setLoading(true);

        userService
            .getProperties(user.id)
            .then((data) => setProperties(data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const saveProperties = (values) => {
        setLoading(true);

        userService
            .postProperties(user.id, values)
            .then((data) => notify.success("The properties have been updated"))
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const loadOrders = () => {
        setLoading(true);

        ecommerceService
            .getOrders(user.id)
            .then((data) => setOrders(data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };
    //#endregion

    //#region Cart
    useEffect(() => {
        const c = JSON.parse(localStorage.getItem("cart")) || [];
        // console.log("ctx cart", c);
        setCart(c);
    }, []);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [JSON.stringify(cart)]);

    const addToCartAndCheckout = (item) => {
        let c = [...cart];
        if (!c.includes(item.slug)) {
            c.push(item.slug);
            setCart(c);
            setSelectedItem(item);
        }
        navigate("/app/checkout");
    };

    const addToCart = (item) => {
        let c = [...cart];
        if (!c.includes(item.slug)) {
            c.push(item.slug);
            setCart(c);
            setSelectedItem(item);
            setAddToCartModalVisible(true);
        }
    };

    const removeFromCart = (item) => {
        let c = [...cart];
        const idx = c.indexOf(item);
        if (idx > -1) {
            c.splice(idx, 1);
            setCart(c);
            notify.success("The item has been removed from your cart.");
        }
    };

    const emptyCart = () => {
        localStorage.removeItem("cart");
        setCart([]);
    };

    const isInCart = (item) => {
        let c = [...cart];
        return c.indexOf(item) > -1;
    };
    //#endregion

    //#region Subscription
    const updateSubscription = (sub) => {
        setSubscription(sub);
        if (sub !== null) localStorage.setItem("subscription", JSON.stringify(sub));
        else localStorage.removeItem("subscription");
    };

    const getSubscription = () => {
        if (subscription) return subscription;

        let s = JSON.parse(localStorage.getItem("subscription"));
        if (s) setSubscription(s);
        return s;
    };
    //#endregion

    return (
        <siteContext.Provider
            value={{
                user,
                properties,
                orderData,
                loading,
                isHydrated,
                isDarkTheme,

                cart,
                addToCartAndCheckout,
                addToCart,
                removeFromCart,
                emptyCart,
                isInCart,

                loadUser,
                updateSubscription,
                getSubscription,
                updateEmail,
                updatePhone,
                updateUserProfile,
                updateUserPassword,
                loadProperties,
                saveProperties,
                loadOrders,
                setLoading,
                setIsDarkTheme,
            }}
        >
            {props.children}
            <CartModal visible={addToCartModalVisible} item={selectedItem} onCancel={() => setAddToCartModalVisible(false)} />
        </siteContext.Provider>
    );
};

// whatever uses this will be wrapped with the Provider
export default ({ element }) => (
    <SiteProvider>
        <SiteConfigProvider>{element}</SiteConfigProvider>
    </SiteProvider>
);
