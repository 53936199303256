import React, { useContext, useEffect } from "react";
import { ConfigProvider, theme } from "antd";
import { siteContext } from "context/SiteContext";

/*
    This function can wrap around any ant design components to set a standard Empty state

    i.e.:
        <SiteConfigProvider to={`/some/url`}>
            <Table ... />
        </SiteConfigProvider>
*/

export default function SiteConfigProvider({ children }) {
    const ctx = useContext(siteContext);

    useEffect(() => {}, [ctx.isDarkTheme]);

    return (
        <ConfigProvider
            theme={{
                algorithm: ctx.isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
                token: ctx.isDarkTheme
                    ? { fontSize: 16, colorBgLayout: "linear-gradient(135deg, hsla(0, 0%, 0%, 1) 0%, hsla(220, 44%, 12%, 1) 50%, hsla(0, 0%, 0%, 1) 100%)" }
                    : { fontSize: 16, colorBgLayout: "#f2f2f2" },
                components: {
                    Typography: {
                        fontSizeHeading1: 40,
                        fontWeightStrong: 300,
                    },
                    Card: {
                        colorTextDescription: "rgba(0, 0, 0, 0.65)",
                        fontSize: 14,
                    },
                    Input: {
                        paddingBlock: 12,
                    },
                    Select: {
                        controlHeight: 46,
                    },
                    Checkbox: {
                        controlInteractiveSize: 22,
                    },
                    Button: {
                        controlHeight: 46,
                    },
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
}
