import { notification } from 'antd';
import { navigate } from 'gatsby'

export const notify = {
    success,
    error
};

function success(m, clickUrl) {
    notification.success({
        message: good[Math.floor(Math.random() * good.length)],
        description: m,
        duration: 3,
        onClick: () => { if(clickUrl) navigate(clickUrl) }
    });    
}

function error(m, clickUrl) {
    notification.error({
        message: bad[Math.floor(Math.random() * bad.length)],
        description: m,
        duration: 3,
        onClick: () => { if(clickUrl) navigate(clickUrl) }
    });
}

let good = [
    "Success",
    "Good Job",
    "Excellent",
    "Way to go!",
    "Nice!",
    "That'll do!",    
    "That worked!",
    "Ok",
    "Bingo"
];

let bad = [    
    "Bummer",
    "Something didn't work",
    "Error",
    "Nope",
    "Shucks"
];