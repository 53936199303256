import axios from "./AxiosHelper";

// define public methods
export const userService = {
    register,
    getSelf,
    updateEmail,
    updatePhone,
    updateName,
    updatePassword,
    getProperties,
    postProperties,
    downloadData,
    deleteAccount,
};

function register(data) {
    return axios.postData(`/api/user/register`, data, true);
}

function getSelf() {
    return axios.getData(`/api/user/me`, true);
}

function updateEmail(data) {
    return axios.putData(`/api/user/${data.id}/email`, data, true);
}

function updatePhone(data) {
    return axios.putData(`/api/user/${data.id}/phone`, data, true);
}

function updateName(data) {
    return axios.putData(`/api/user/${data.id}`, data, true);
}

function updatePassword(values) {
    return axios.putData(`/api/user/${values.id}/password`, values, true);
}

function getProperties(id) {
    return axios.getData(`/api/user/${id}/properties`, true);
}

function postProperties(id, values) {
    return axios.postData(`/api/user/${id}/properties`, values, true);
}

function downloadData() {
    return axios.getData(`/api/user/me/download`, true);
}

function deleteAccount() {
    return axios.deleteData(`/api/user/me`, true);
}
