import React from "react";
import { Link, navigate } from "gatsby";
import { Modal, Button } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

export default ({ visible, item, onCancel }) => {
    const goToCheckout = () => {
        navigate("/cart");
        onCancel && onCancel();
    };

    return (
        <Modal
            open={visible}
            title={
                <strong>
                    <CheckCircleTwoTone twoToneColor="#52c41a" className="mr-2" />
                    Added to Cart
                </strong>
            }
            onCancel={() => onCancel && onCancel()}
            footer={[
                <Button key="submit" type="primary" onClick={onCancel}>
                    Close
                </Button>,
                <Button key="cart" type="primary" className="ml-2" onClick={goToCheckout}>
                    Checkout
                </Button>,
            ]}
        >
            <p>An item has been added to your cart</p>

            {item && <div>{item.title}</div>}
        </Modal>
    );
};
